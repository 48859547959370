import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import Button from '../Button/Button';
import { ButtonVariant } from '../Button/enums';
import type { LoadingButtonProps } from '../Button/types';
import localStyles from './HeroButton.module.pcss';

type HeroButtonProps = Omit<LoadingButtonProps, 'variant' | 'size'>;

/**
 * A variant of the large button with more x-axis padding.
 *
 * @author Willi Hyde
 */
const HeroButton: ForwardRefExoticComponent<
  PropsWithoutRef<HeroButtonProps> & RefAttributes<HTMLButtonElement>
> = forwardRef(function ButtonForward(
  {
    className,
    loading,
    onClick,
    children,
    block,
    active,
    disabled,
    href,
    type,
    title,
    as,
    name,
    testId = 'Button',
    ...props
  },
  ref
) {
  const cx = useClassNameMapper(localStyles);

  return (
    <Button
      title={title}
      variant={ButtonVariant.PRIMARY}
      size="lg"
      block={block}
      active={active}
      disabled={loading ? true : disabled}
      href={href}
      type={type}
      as={as}
      onClick={onClick}
      className={cx(className, 'lia-btn')}
      data-testid={testId}
      ref={ref}
      name={name}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Button>
  );
});

export default HeroButton;
